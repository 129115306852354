body {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100%;
  display: flex;
  scroll-behavior: smooth !important;
  -webkit-scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  font-family: "Open Sans", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  /*height: 100%;*/
  width: 100vw;
}
